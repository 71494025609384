import Highcharts from "highcharts/highstock";

import MomentTimeZone from "moment-timezone";
import Moment from "moment";
import authentication from "./Authentication";

MomentTimeZone();
Moment();

window.moment = Moment;

var elexity = elexity || {};
elexity.gx = elexity.gx || {};

elexity.gx.battery = {
	allocationGraph : {},
	batteryEnergyGraph : {},
	mGridInterconnectEntityKey : null,
	graphDefinition : null,

	init : async function(batteryData, entityKey, url, token, showMobile) {
		const mResponse = await fetch(url, { headers: token });
        const mJson = await mResponse.json();
		this.mGridInterconnectEntityKey = entityKey;
        
        if (!mResponse.ok) {
            if (mResponse.status === 401) {
                authentication.logout()
                window.location.replace(authentication.logoutLink)
            }
        }

		if (!showMobile) {
			batteryData.gridInterconnects.forEach((gridInterconnectModel) => {
				elexity.gx.battery.buildInitialGraphs(gridInterconnectModel);
				elexity.gx.battery.renderGridInterconnectGraphs(gridInterconnectModel);
			});
		}

		this.initializeBatteryEnergyGraph(mJson);
	},

    renderGraphs : async function(batteryData, url, token, showMobile) {
		const mResponse = await fetch(url, { headers: token });
        const mJson = await mResponse.json();
        
        if (!mResponse.ok) {
            if (mResponse.status === 401) {
                authentication.logout()
                window.location.replace(authentication.logoutLink)
            }
        }

		if (!showMobile) {
			batteryData.gridInterconnects.forEach((gridInterconnectModel) => {
				elexity.gx.battery.buildInitialGraphs(gridInterconnectModel);
				elexity.gx.battery.renderGridInterconnectGraphs(gridInterconnectModel);
			});
		}

		this.renderBatteryEnergyGraph(mJson)
	},
	
	renderGridInterconnectGraphs : function(gridInterconnectModel) {
        var socPercent = gridInterconnectModel.socPercent;
		var gridOutageRisk;
		var backupPower =  gridInterconnectModel.backupPowerSupported;
        var allocationData = [];
        var availableData = [];
        var unalloactedData = [];

		if (gridInterconnectModel.gridOutageRisk === "None") {
			gridOutageRisk = gridInterconnectModel.batteryBackupReservation.noRisk
		} else if (gridInterconnectModel.gridOutageRisk === "Low") {
			gridOutageRisk = gridInterconnectModel.batteryBackupReservation.lowRisk
		} else if (gridInterconnectModel.gridOutageRisk === "Medium") {
			gridOutageRisk = gridInterconnectModel.batteryBackupReservation.mediumRisk
		} else if (gridInterconnectModel.gridOutageRisk === "High") {
			gridOutageRisk = gridInterconnectModel.batteryBackupReservation.highRisk
		}
        
        if (gridInterconnectModel !== null) {
            gridInterconnectModel.allocations.map((entry, entryIndex) => {
				if (availableData.length < gridInterconnectModel.allocations.length) {
					if (gridInterconnectModel.allocations[entryIndex] != null && gridInterconnectModel.allocations[entryIndex] != undefined) {
						var allocatedVar = 100 - socPercent;
						var availableVar = entry.allocated - allocatedVar;
                        var unallocatedVar = 100 - entry.allocated;

						if (availableVar < 0) {
							availableVar = 0
						}

						allocationData.push(allocatedVar);
						availableData.push(availableVar);
                        unalloactedData.push(unallocatedVar);
					}
				}
            });

            elexity.gx.battery.allocationGraph.series[0].setData(allocationData, false, false);
            elexity.gx.battery.allocationGraph.series[1].setData(availableData, false, false);
            elexity.gx.battery.allocationGraph.series[2].setData(unalloactedData, false, false);

            // Modify the graph definitions, in case the API is returning other info 
            var mGraphUpdateDefinition = {
                yAxis: {
                    min: 0,
                    max: 100,
                    plotLines: [
						{
							color: '#004C93',
							width: 2,
							value: socPercent,
							dashStyle: "shortDash",
							label: {
								useHTML: true,
								formatter: function () {
									return "<div style='background: rgba(250, 255, 255, 0.85)'>SOC (" + this.options.value + "%)</div>"
								},
								style: {
									color: "#004C93",
									fontWeight: "600",
								},
							}
                    	},
					],
                },
            };

			if (gridInterconnectModel.backupPowerSupported && gridInterconnectModel.backupPowerSupported !== false) {
				mGraphUpdateDefinition.yAxis.plotLines.push({
					color: '#F47315',
					width: 3,
					value: gridOutageRisk,
					dashStyle: "dotDash",
					label: {
						useHTML: true,
						formatter: function () {
							return "<div style='background: rgba(250, 255, 255, 0.85); text-align: right;'>Backup Power (" + this.options.value + "%)</div>"
						},
						align: "right",
						style: {
							color: "#DA650F",
							fontWeight: "600",
						},
					},
					zIndex: 3
				},)
			}
                        
            // Re-render
            elexity.gx.battery.allocationGraph.update(mGraphUpdateDefinition, true, true, true);
        }
	},
	
	buildInitialGraphs : function(gridInterconnectModel) {
		var allocationXLabels = []
		gridInterconnectModel.allocations.map(item => {
			allocationXLabels.push(item.category)
		})

		if (Object.keys(this.allocationGraph).length === 0) {
			var mGraphDiv = "allocation-graph-" + this.mGridInterconnectEntityKey;

			var mSeriesDefinitions = [
				{
					name: "Allocated / Used",
					color: "#069BCE",
					type: "column",
					stacking: "percent",
					opacity: .85,
					dataGrouping: { approximation: "high"},
					dataLabels: [{
						color: "#02222B",
						enabled: true,
						verticalAlign: 'top',
						format: "Used: {point.y}%",
						style: {
							fontSize: "12",
							fontWeight: "500"
						}
					}],
					showInLegend: false
				},
				{
					name: "Allocated / Available",
					color: "#2FE0E6",
					type: "column",
					stacking: "percent",
					opacity: .85,
					dataGrouping: { approximation: "high"},
					dataLabels: [{
						color: "#02222B",
						enabled: true,
						verticalAlign: 'top',
						format: "Available: {point.y}%",
						style: {
							fontSize: "12",
							fontWeight: "500"
						}
						
					}],
					showInLegend: false
				},
                {
                    name: "Unavailable",
                    color: "#FFFFFF",
                    type: "column",
                    stacking: "percent",
                    opacity: 0.0,
                    dataGrouping: { approximation: "high"},
                    dataLabels: [{
						color: "#02222B",
						enabled: true,
                        verticalAlign: 'top',
                        format: "",
                    }],
					showInLegend: false
                }
			];
			
			var mGraphConfiguration = {
				chart: {
					zooming: {
						mouseWheel: false
					},
					style : {
						fontFamily: "Inter",
						color: "#02222B",
					}  
				},
				credits: { enabled: false},                              
                title: {
                    text: "Battery Percent (%)",
					style: {
						color: "#02222B",
						fontFamily: "Inter",
						fontSize: "16",
						fontWeight: "600",
					}
                },
	            xAxis: {
	                categories: allocationXLabels,
					labels: {
						style: {
							color: "#02222B"
						}
					},
	            },
                yAxis: {
                    min: 0,
                    max: 100,                    
                    plotLines: [],
					title: {
						text: ""
					},
					labels: {
						style: {
							color: "#02222B"
						}
					},
                },                
				legend: {
					align: 'left',
					verticalAlign: 'top',
				},
	            plotOptions: {
	                series: {
						dataLabels: {
							enabled: true,
							inside: true,
						},
	                    marker: {
	                        enabled: false,
	                        radius: 3
	                    },
						lineWidth: 3,
	                    stacking: "normal",
			            pointPadding: 0,
						maxPointWidth: 40,
			            groupPadding: 0,
			            borderWidth: 0,
			            shadow: false
	                }
            	},	            	            
	            series: mSeriesDefinitions,    
                tooltip: {
                    shared: true,
                    crosshairs: true,
                    useHTML: true,
                    headerFormat: "<table style='background: white; border-radius: 8px; color: #02222B; font-size: smaller;'><tr><th colspan='2'>{point.key}</th></tr>",
                    pointFormat: "<tr><td style='color: #02222B; text-align: left'>{series.name} </td><td style='color: #02222B; text-align: left'><b>{point.y} %</b></td></tr>",
                    footerFormat: "</table>",
					padding: 6,
					borderRadius: 8,
                }
	        };
	        
	        elexity.gx.battery.allocationGraph = Highcharts.chart(mGraphDiv, mGraphConfiguration); 
		}
	},

	batteryEnergySeriesDefinitions : [
        {
            name: "Discharge To Grid",
            color: "#069BCE",
            dataGrouping: { approximation: 'sum'}
        },
        {
            name: "Discharge To Loads",
            color: "#00DC92",
            dataGrouping: { approximation: 'sum'}
        },
        {
            name: "Charge From Grid",
            color: "#2FE0E6",
            dataGrouping: { approximation: 'sum'}
        },
        {
            name: "Charge From Solar",
            color: "#FA974F",
            dataGrouping: { approximation: 'sum'}            
        }
    ],

	buildDateString : function(ts, tz) {
        var mFormat = new Intl.DateTimeFormat('en-GB', {
            dateStyle: "medium",            
            timeStyle: "long",
            hourCycle: "h24",
            timeZone: tz
        });
        
        var mDate = new Date(ts);

        return mFormat.format(mDate);
    },
    
    initializeBatteryEnergyGraph : function(mJson) {
		var mGraphDiv = "history-graph-" + this.mGridInterconnectEntityKey;

		elexity.gx.battery.graphDefinition = mJson;

        var mGraphConfiguration = {
           chart: {
               type: "column",
               zooming : { 
                   mouseWheel : {
                       enabled : false
                   }
               },
			   style : {
				fontFamily: "Inter",
			   }           
           },
           navigator: {
               enabled: false
           },
           rangeSelector: {
               enabled: false
           },
           scrollbar: {
                enabled: false
           },
           credits: { enabled: false},                           
           title: {
               text: "Battery Energy",
			   style: {
				color: "#02222B",
				fontSize: "16",
				fontWeight: "600",
			}
           },
           legend: {
               enabled: true,
			   itemStyle: {
				color: "#02222B",
				cursor: "pointer",
				fontSize: "12px",
				fontWeight: "500",
			  }
           },
           yAxis: [{
               lineWidth: 1,
               title: {
                   text: "kWh"
               },
               opposite: false,
			   labels: {
					style: {
						color: "#02222B"
					}
				}, 
           }],           
           xAxis: {
               type: 'datetime',
               labels: {
                   format: '{value:%b-%e %H:%M}',
				   style: {
						color: "#02222B"
				   }
               }, 
               ordinal : false,
           },      
		   time: {
				timezone: elexity.gx.battery.graphDefinition.timeZoneCode
			},     
           plotOptions: {
                column : {
                    stacking: "normal",
                    lineWidth: 3,
                    marker: {
                        enabled: false
                    }
                },
				series : {
					maxPointWidth: 60,
				}
           },                              
           series: elexity.gx.battery.batteryEnergySeriesDefinitions,    
           tooltip: {
               shared: true,
               crosshairs: true,
               useHTML: true,
			   borderWidth: 0,
               formatter() {
                   let s = '<table style="width: 200px; font-size: smaller;"><tr><th colspan="2">' + elexity.gx.battery.buildDateString(this.x, elexity.gx.battery.graphDefinition.timeZoneCode) + '</th></tr>' 
				   
                   this.points.forEach(point => {
                        if (point.y != 0) {
                            s += '<tr><td style="color: #02222B">' + point.series.name + '</td><td style="text-align: right"><b>' + point.y.toFixed(2) + ' kWh</b></td></tr>';
                        }
                   });

                   return s;
               }
           }
       };

       elexity.gx.battery.batteryEnergyGraph = Highcharts.stockChart(mGraphDiv, mGraphConfiguration); 

	   this.renderBatteryEnergyGraph(mJson);
    },
    
    renderBatteryEnergyGraph : function(graphModel) {
        var mNameToModelSeriesIndexMap = {};
        graphModel.seriesNames.forEach((mSeriesName, seriesIndex) => {
            mNameToModelSeriesIndexMap[mSeriesName] = seriesIndex;            
        });         

        elexity.gx.battery.batteryEnergySeriesDefinitions.forEach((mSeriesDef, seriesIndex) => {
            var mData = [];
            var mModelSeriesIndex = mNameToModelSeriesIndexMap[mSeriesDef.name];

            graphModel.entries.forEach((entry) => {
                if (entry.values[mModelSeriesIndex] == null) {
                    mData.push([entry.timestamp, 0]);
                }
                else {
                    mData.push([entry.timestamp, entry.values[mModelSeriesIndex]]);
                }
            });
            
            elexity.gx.battery.batteryEnergyGraph.series[seriesIndex].setData(mData, false, false)
        });
        
        elexity.gx.battery.batteryEnergyGraph.redraw();
    }
}

export { elexity }