import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from 'highcharts/modules/exporting'
import MomentTimeZone from 'moment-timezone'
import Moment from 'moment'
import authentication from './Authentication'

MomentTimeZone();
Moment();

window.moment = Moment;
require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

var elexity = elexity || {};
elexity.admin = elexity.admin || {};
elexity.admin.monitor = elexity.admin.monitor || {};

elexity.admin.monitor.graphs = {
    entityKey : "",
    definitionUrl : "",
    historyUrl : "",
    liveUrl : "",
    
    historyDivName : "",
    liveDivName : "",

    liveHistorySubtitle: "",
    
    performanceHistoryGraph : null,
    performanceLiveGraph : null,
    
    historySummaryLoading : false,
    historySummaryLoaded : false,
    
    liveLoading : false,
    
    focusFromTimestamp : null,
    focusToTimestamp : null,
    
    lastLiveTimestamp : null,    
    
    graphDefinition : null,
    graphConfiguration : {},
    
    intervalHook : null,
    
    authToken : null,
    
    logAndload : async function(_step, url, callback) {
        // console.log(step + " : " + url);
        
        const mResponse = await fetch(url, { headers: elexity.admin.monitor.graphs.authToken });
        const mJson = await mResponse.json();
        
        if (!mResponse.ok) {
            if (mResponse.status === 401) {
                authentication.logout()
                window.location.replace(authentication.logoutLink)
            }
        }

        callback(mJson);
    },
    
    logEvent : function(_event) {
//        console.log(new Date().toISOString() + event);        
    },
    
    initAuth(authToken) {
        elexity.admin.monitor.graphs.authToken = authToken;
    },
    
    init : function(entityKey, definitionUrl, historyUrl, liveUrl, graphConfiguration, historyDivName, liveDivName, subtitle) {
        elexity.admin.monitor.graphs.logEvent("init : enter");
        elexity.admin.monitor.graphs.entityKey = entityKey;
        elexity.admin.monitor.graphs.definitionUrl = definitionUrl.replaceAll("{entityKey}", entityKey);
        elexity.admin.monitor.graphs.historyUrl = historyUrl.replaceAll("{entityKey}", entityKey);
        elexity.admin.monitor.graphs.liveUrl = liveUrl.replaceAll("{entityKey}", entityKey);       
        elexity.admin.monitor.graphs.graphConfiguration = graphConfiguration; 
        elexity.admin.monitor.graphs.historyDivName = historyDivName; 
        elexity.admin.monitor.graphs.liveDivName = liveDivName; 
        elexity.admin.monitor.graphs.liveHistorySubtitle = subtitle;
    
        elexity.admin.monitor.graphs.performanceHistoryGraph = null;
        elexity.admin.monitor.graphs.performanceLiveGraph = null;
        elexity.admin.monitor.graphs.historySummaryLoaded = false;
        elexity.admin.monitor.graphs.historySummaryLoading = false;
        elexity.admin.monitor.graphs.liveLoading = false;
        elexity.admin.monitor.graphs.focusFromTimestamp = null;
        elexity.admin.monitor.graphs.focusToTimestamp = null;
        elexity.admin.monitor.graphs.lastLiveTimestamp = null;
                        
        elexity.admin.monitor.graphs.logAndload("define : ", elexity.admin.monitor.graphs.definitionUrl, elexity.admin.monitor.graphs.initializeGraphs);        
                
        if (elexity.admin.monitor.graphs.intervalHook != null) {
            clearInterval(elexity.admin.monitor.graphs.intervalHook);
            elexity.admin.monitor.graphs.intervalHook = null;
        }        
        elexity.admin.monitor.graphs.logEvent("init : exit");
    },
    
    halt : function() {
        elexity.admin.monitor.graphs.logEvent("halt : enter");
        elexity.admin.monitor.graphs.performanceHistoryGraph = null;
        elexity.admin.monitor.graphs.performanceLiveGraph = null;
        elexity.admin.monitor.graphs.historySummaryLoaded = false;
        elexity.admin.monitor.graphs.historySummaryLoading = false;
        elexity.admin.monitor.graphs.liveLoading = false;
        elexity.admin.monitor.graphs.focusFromTimestamp = null;
        elexity.admin.monitor.graphs.focusToTimestamp = null;
        elexity.admin.monitor.graphs.lastLiveTimestamp = null;
                        
        if (elexity.admin.monitor.graphs.intervalHook != null) {
            clearInterval(elexity.admin.monitor.graphs.intervalHook);
            elexity.admin.monitor.graphs.intervalHook = null;
        }        

        elexity.admin.monitor.graphs.logEvent("halt : exit");
    },
    
    triggerHistoryGraphReload : function() {
        elexity.admin.monitor.graphs.historySummaryLoaded = false;
        elexity.admin.monitor.graphs.historySummaryLoading = false;

        elexity.admin.monitor.graphs.pulseHistoryGraph();
    },
    
    pulseLiveGraph : function() {
        elexity.admin.monitor.graphs.logEvent("pulseLiveGraph : enter");
        if (elexity.admin.monitor.graphs.performanceLiveGraph != null && !elexity.admin.monitor.graphs.liveLoading) {
            elexity.admin.monitor.graphs.liveLoading = true;
            elexity.admin.monitor.graphs.performanceLiveGraph.showLoading();            
            var mUrl = elexity.admin.monitor.graphs.liveUrl;
            if (elexity.admin.monitor.graphs.lastLiveTimestamp != null) {
                mUrl += "?liveFrom=" + elexity.admin.monitor.graphs.lastLiveTimestamp;
            }

            elexity.admin.monitor.graphs.logAndload("pulse : live : ", mUrl, elexity.admin.monitor.graphs.renderLiveGraphData);
        }
        elexity.admin.monitor.graphs.logEvent("pulseLiveGraph : exit");
    },

    pulseHistoryGraph : function() {
        elexity.admin.monitor.graphs.logEvent("pulseHistoryGraph : enter");
        if (elexity.admin.monitor.graphs.performanceHistoryGraph != null) {
            if (!elexity.admin.monitor.graphs.historySummaryLoaded) {
                if (!elexity.admin.monitor.graphs.historySummaryLoading) {
                    elexity.admin.monitor.graphs.historySummaryLoading = true;
                    elexity.admin.monitor.graphs.performanceHistoryGraph.showLoading();
                    var mUrl = elexity.admin.monitor.graphs.historyUrl;
                    elexity.admin.monitor.graphs.logAndload("pulse : history : full : ", mUrl, elexity.admin.monitor.graphs.renderHistorySummaryGraphData);
                }
            }
            else {
                var mFocusFrom = elexity.admin.monitor.graphs.focusFromTimestamp;
                var mFocusTo = elexity.admin.monitor.graphs.focusToTimestamp;
    
                elexity.admin.monitor.graphs.focusFromTimestamp = null;
                elexity.admin.monitor.graphs.focusToTimestamp = null;
                
                if (mFocusFrom != null && mFocusTo != null) {
                    elexity.admin.monitor.graphs.performanceHistoryGraph.showLoading();
                    var mUrl = elexity.admin.monitor.graphs.historyUrl + "?focusFrom=" + mFocusFrom + "&focusTo=" + mFocusTo;
                    elexity.admin.monitor.graphs.logAndload("pulse : history : focus : ", mUrl, elexity.admin.monitor.graphs.renderHistoryFocusGraphData);
                }
            }
        }
        elexity.admin.monitor.graphs.logEvent("pulseHistoryGraph : exit");
    },
    	        
    createBaseDefinition : function() {
        var mBaseGraphDefinition = {
            chart : { zoomType: 'x', 
                zooming : { 
                    mouseWheel : {
                        enabled : false
                    }
                }  },           
            credits: { enabled: false },                
            title: { text:  elexity.admin.monitor.graphs.graphDefinition.graphTitle}, 
            // subtitle : { text : "" },               
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%b-%e %H:%M}'
                }, 
                ordinal : false
            },
            yAxis: [{
                lineWidth: 1,
                title: {
                    text: elexity.admin.monitor.graphs.graphDefinition.unit
                },
                opposite: false
            }],
            tooltip: {
                shared: true,
                crosshairs: true,
                useHTML: true,
                borderColor: 'gray',
                borderWidth: 2,
                shadow: false,
                formatter() {
                    let s = '<table style="width: 200px; font-size: small;"><tr><th colspan="2">' + elexity.admin.monitor.graphs.buildDateString(this.x, elexity.admin.monitor.graphs.graphDefinition.timeZoneCode) + '</th></tr>' 

                    this.points.forEach(point => {
                        const hex2rgb = (hex) => {
                            const r = parseInt(hex.slice(1, 3), 16);
                            const g = parseInt(hex.slice(3, 5), 16);
                            const b = parseInt(hex.slice(5, 7), 16);
                            const a = 0.6;

                            const newRGBA = `rgba(${r}, ${g}, ${b}, ${a})`

                            return newRGBA;
                        }
                        const rgba = hex2rgb(point.color)

                        s += '<tr><td style="padding: 5px; font-weight: 700; color: black; background-color: ' + rgba + '">' + point.series.name + '</td><td style="text-align: right; padding: 5px; font-weight: 600; color: black; background-color:' + rgba + '"><b>' + point.y.toFixed(elexity.admin.monitor.graphs.graphDefinition.precision) + ' ' + elexity.admin.monitor.graphs.graphDefinition.unit + '</b></td></tr>';
                    });
    
                    return s;
                },                
                valueDecimals: elexity.admin.monitor.graphs.graphDefinition.precision
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                        radius: 3
                    },
                    lineWidth: 3,
                }
            },
            time: {
                timezone: elexity.admin.monitor.graphs.graphDefinition.timeZoneCode
            },
            legend: {
                enabled: true,
            },
            series: elexity.admin.monitor.graphs.graphConfiguration.seriesDefinitions
        };
        
        if (elexity.admin.monitor.graphs.graphConfiguration.yAxes != null) {
            elexity.admin.monitor.graphs.graphConfiguration.yAxes.forEach(yAxis => {
                mBaseGraphDefinition.yAxis.push(yAxis)});
        }
        
        return mBaseGraphDefinition;
    },     
        
    initializeGraphs : function(graphDefinition) {
        elexity.admin.monitor.graphs.logEvent("initializeGraphs : enter");
        
        elexity.admin.monitor.graphs.graphDefinition = graphDefinition;
        
        elexity.admin.monitor.graphs.initializeHistoryGraph();
        elexity.admin.monitor.graphs.initializeLiveGraph();
        
        setTimeout(elexity.admin.monitor.graphs.postInitialize, 10);

        elexity.admin.monitor.graphs.logEvent("initializeGraphs : exit");
    },
    
    postInitialize : function() {
        elexity.admin.monitor.graphs.logEvent("postInitialize : enter");
        elexity.admin.monitor.graphs.pulseLiveGraph();
        elexity.admin.monitor.graphs.pulseHistoryGraph();
        elexity.admin.monitor.graphs.intervalHook = setInterval(elexity.admin.monitor.graphs.pulseLiveGraph, 15000);          
        elexity.admin.monitor.graphs.intervalHook = setInterval(elexity.admin.monitor.graphs.pulseHistoryGraph, 1000);           
        elexity.admin.monitor.graphs.logEvent("postInitialize : exit");
    },
        
    initializeHistoryGraph : function() {
        elexity.admin.monitor.graphs.logEvent("initializeHistoryGraph : enter");
        if (elexity.admin.monitor.graphs.performanceHistoryGraph == null) {
            var mGraphDefinition = elexity.admin.monitor.graphs.createBaseDefinition();
            mGraphDefinition.title.text = mGraphDefinition.title.text + " - Historical";
            
            var mExtremesEvent = function(event) {
                elexity.admin.monitor.graphs.performanceHistoryGraph.showLoading();
                
                // Why not just load? We want to swallow a bunch of excess selection events. May not actually be needed.
                
                elexity.admin.monitor.graphs.focusFromTimestamp = event.min;
                elexity.admin.monitor.graphs.focusToTimestamp = event.max;

                var mGraphUpdateDefinition = {
                    subtitle: { text: elexity.admin.monitor.graphs.liveHistorySubtitle }                
                };

                elexity.admin.monitor.graphs.performanceHistoryGraph.update(mGraphUpdateDefinition, true);
            };
    
            mGraphDefinition.xAxis.events = {};            
            mGraphDefinition.xAxis.events.setExtremes = mExtremesEvent;
            
            mGraphDefinition.rangeSelector = {
                selected: 4,
                buttons : [
                    {type: 'hour', count: 3, text: '3h', title: 'View 3 hours'},
                    {type: 'hour', count: 6, text: '6h', title: 'View 6 hours'},
                    {type: 'day', count: 1, text: '1d', title: 'View 1 day'},
                    {type: 'day', count: 3, text: '3d', title: 'View 3 days'}, 
                    {type: 'day', count: 7, text: '1w', title: 'View 1 week'}, 
                    {type: 'month', count: 1, text: '1m', title: 'View 1 month'}, 
                    {type: 'month', count: 3, text: '3m', title: 'View 3 months'}, 
                    {type: 'month', count: 6, text: '6m', title: 'View 6 months'}, 
                    {type: 'ytd', text: 'YTD', title: 'View year to date'}, 
                    {type: 'year', count: 1, text: '1y',title: 'View 1 year'}, 
                    {type: 'all', text: 'All', title: 'View all'}
                ]
            };
            mGraphDefinition.xAxis.minRange = 50 * 60 * 1000;
            
            elexity.admin.monitor.graphs.performanceHistoryGraph = Highcharts.stockChart(elexity.admin.monitor.graphs.historyDivName, mGraphDefinition);
            elexity.admin.monitor.graphs.performanceHistoryGraph.rangeSelector.selected = mGraphDefinition.rangeSelector.selected
        }
        elexity.admin.monitor.graphs.logEvent("initializeHistoryGraph : exit");
    },     

    initializeLiveGraph : function() {
        elexity.admin.monitor.graphs.logEvent("initializeLiveGraph : enter");
        if (elexity.admin.monitor.graphs.performanceLiveGraph == null) {
            var mGraphDefinition = elexity.admin.monitor.graphs.createBaseDefinition(elexity.admin.monitor.graphs.graphDefinition);      
            mGraphDefinition.title.text = mGraphDefinition.title.text + " - Live";
            
            var mExtremesEvent = function(event) {
                var mGraphUpdateDefinition = {
                    subtitle: { text: elexity.admin.monitor.graphs.liveHistorySubtitle }                
                };

                elexity.admin.monitor.graphs.performanceLiveGraph.update(mGraphUpdateDefinition, true);
            };
    
            mGraphDefinition.xAxis.events = {};            
            mGraphDefinition.xAxis.events.setExtremes = mExtremesEvent;
            
            var mSelectedZoom = 2;            
            if (elexity.admin.monitor.graphs.graphConfiguration.defaultLiveZoom != null && elexity.admin.monitor.graphs.graphConfiguration.defaultLiveZoom != undefined) {
                mSelectedZoom = elexity.admin.monitor.graphs.graphConfiguration.defaultLiveZoom; 
            }

            mGraphDefinition.rangeSelector = {
                selected: mSelectedZoom,
                buttons : [
                    {type: 'minute', count: 15, text: '15m', title: 'View 15 minutes'},
                    {type: 'minute', count: 30, text: '30m', title: 'View 30 minutes'},
                    {type: 'hour', count: 1, text: '1h', title: 'View 1 hour'},
                    {type: 'all', text: 'All', title: 'View all'}
                ]
            };

            mGraphDefinition.xAxis.minRange = 1 * 60 * 1000;

            mGraphDefinition.xAxis.labels.format = '{value:%b-%e %H:%M:%S}';

            this.performanceLiveGraph = Highcharts.stockChart(elexity.admin.monitor.graphs.liveDivName, mGraphDefinition); 
        }
        elexity.admin.monitor.graphs.logEvent("initializeLiveGraph : exit");
    },

    renderHistorySummaryGraphData : function(graphModel) {
        elexity.admin.monitor.graphs.logEvent("renderHistorySummaryGraphData : enter");
        
        var mNameToIndexMap = elexity.admin.monitor.graphs.getNameToIndexMap(graphModel);
        
        elexity.admin.monitor.graphs.graphConfiguration.seriesDefinitions.forEach((mSeriesDef, seriesIndex) => {
            var mDataIndex = mNameToIndexMap[mSeriesDef.name];
            if (mDataIndex != null && mDataIndex != undefined) {
                var mData = [];

                graphModel.entries.forEach((entry) => {
                    if (entry.values[mDataIndex] != null && elexity.admin.monitor.graphs.performanceHistoryGraph.series != null &&  elexity.admin.monitor.graphs.performanceHistoryGraph.series[seriesIndex] != null) {
                        var mValue = elexity.admin.monitor.graphs.roundToNumber(entry.values[mDataIndex], graphModel.precision);
                        mData.push([entry.timestamp, mValue]);                   
                    }
                });
                
                elexity.admin.monitor.graphs.performanceHistoryGraph.series[seriesIndex].setData(mData, false, false);

            }            
        });

        var mGraphUpdateDefinition = {};

        //set selected zoom for subtitle
        var zoomSelected = elexity.admin.monitor.graphs.performanceHistoryGraph.rangeSelector.selected;
        var buttonOptions = elexity.admin.monitor.graphs.performanceHistoryGraph.rangeSelector.buttonOptions;

        if (zoomSelected !== undefined) {
            var formattedIntervalText = buttonOptions[zoomSelected].text;
            formattedIntervalText = formattedIntervalText + ' intervals ' + '(' + graphModel.timeZoneCode + ')';
            elexity.admin.monitor.graphs.selectedHistoryZoom = formattedIntervalText;
        }
        
        mGraphUpdateDefinition.xAxis = {
            min : graphModel.displayStartTimestamp,
            max : graphModel.displayEndTimestamp                
        };

        elexity.admin.monitor.graphs.performanceHistoryGraph.update(mGraphUpdateDefinition, false);
        
        elexity.admin.monitor.graphs.performanceHistoryGraph.redraw();        
                
        elexity.admin.monitor.graphs.focusFromTimestamp = elexity.admin.monitor.graphs.performanceHistoryGraph.xAxis[0].min;        
        elexity.admin.monitor.graphs.focusToTimestamp = elexity.admin.monitor.graphs.performanceHistoryGraph.xAxis[0].max;        

        elexity.admin.monitor.graphs.historySummaryLoaded = true;        
        elexity.admin.monitor.graphs.historySummaryLoading = false;
        
        elexity.admin.monitor.graphs.logEvent("renderHistorySummaryGraphData : exit");
    },

    calculatePlotBands : function(graphModel) {
        var mPlotBands = [];
        if (graphModel.ranges != null && elexity.admin.monitor.graphs.graphConfiguration.plotBandColors != null && elexity.admin.monitor.graphs.graphConfiguration.plotBandColors != undefined) {
            graphModel.ranges.forEach(range => {
                var mColor = elexity.admin.monitor.graphs.graphConfiguration.plotBandColors[range.rangeCode];
    
                if (mColor != null && mColor != undefined && mColor != "") {
                    var mPlotBand = {
                        from: range.startDateTime,
                        to: range.endDateTime,
                        color: mColor
                    };
    
                    mPlotBands.push(mPlotBand);             
                }            
            });
        }
    
        return mPlotBands;
    },
	
    calculatePlotLines : function(graphModel) {
        var mPlotLines = [];
        if (graphModel.events != null) {
            graphModel.events.forEach(event => {
                  let mPlotLine = {
                    color: '#000000',
                    label: {
                        text: event.eventName,
                        y: 22,
                    },
                    value: event.dateTime,
                    width: 2,
                }
                 mPlotLines.push(mPlotLine)
            });
        }
    
        return mPlotLines;
    },
    
    getNameToIndexMap : function(graphModel) {
        var mNameToIndexMap = {};
        graphModel.seriesNames.forEach((seriesName, index) => {
            var mSeriesName = seriesName;
            if (mSeriesName == 'TPPL') {
                mSeriesName = "Limit";
            }
            
            mNameToIndexMap[mSeriesName] = index;
        });

        return mNameToIndexMap;
    },
	
    renderHistoryFocusGraphData : function(graphModel) {
        elexity.admin.monitor.graphs.logEvent("renderHistoryFocusGraphData : enter");
        var mNameToIndexMap = elexity.admin.monitor.graphs.getNameToIndexMap(graphModel);
        
        elexity.admin.monitor.graphs.graphConfiguration.seriesDefinitions.forEach((mSeriesDef, seriesIndex) => {
            var mDataIndex = mNameToIndexMap[mSeriesDef.name];
            if (mDataIndex != null && mDataIndex != undefined) {
                var mData = [];

                graphModel.entries.forEach((entry) => {
                    if (entry.values[mDataIndex] != null && elexity.admin.monitor.graphs.performanceHistoryGraph.series != null  && elexity.admin.monitor.graphs.performanceHistoryGraph.series[seriesIndex] != null) {
                        var mValue = elexity.admin.monitor.graphs.roundToNumber(entry.values[mDataIndex], graphModel.precision);
                        mData.push([entry.timestamp, mValue]);               
                    }
                });   

                elexity.admin.monitor.graphs.performanceHistoryGraph.series[seriesIndex].setData(mData, false, false);

            }            
        });

        //set selected zoom for subtitle
        var zoomSelected = elexity.admin.monitor.graphs.performanceHistoryGraph.rangeSelector.selected
        var buttonOptions = elexity.admin.monitor.graphs.performanceHistoryGraph.rangeSelector.buttonOptions
        var formattedIntervalText
        if (zoomSelected !== undefined) {
            formattedIntervalText = buttonOptions[zoomSelected].text

            formattedIntervalText = formattedIntervalText + ' intervals ' + '(' + graphModel.timeZoneCode + ')'
            elexity.admin.monitor.graphs.selectedHistoryZoom = formattedIntervalText
        }
        
        var mGraphUpdateDefinition = {
            subtitle: { text: elexity.admin.monitor.graphs.liveHistorySubtitle }                
        };
        
        mGraphUpdateDefinition.xAxis = {
            min : graphModel.displayStartTimestamp,
            max : graphModel.displayEndTimestamp                
        };
        
        var mPlotBands = elexity.admin.monitor.graphs.calculatePlotBands(graphModel);
        if (mPlotBands.length != 0) {
            mGraphUpdateDefinition.xAxis.plotBands =  mPlotBands;
        }
                
        elexity.admin.monitor.graphs.performanceHistoryGraph.update(mGraphUpdateDefinition, false);
        elexity.admin.monitor.graphs.performanceHistoryGraph.redraw();
        
        elexity.admin.monitor.graphs.performanceHistoryGraph.hideLoading();
        elexity.admin.monitor.graphs.logEvent("renderHistoryFocusGraphData : exit");        
    },

    renderLiveGraphData : function(graphModel) {
        elexity.admin.monitor.graphs.logEvent("renderLiveGraphData : enter");
        if (elexity.admin.monitor.graphs.performanceLiveGraph != null) {    
            elexity.admin.monitor.graphs.lastLiveTimestamp = graphModel.nextQueryTimestamp;

            var mNameToIndexMap = elexity.admin.monitor.graphs.getNameToIndexMap(graphModel);
            
            elexity.admin.monitor.graphs.graphConfiguration.seriesDefinitions.forEach((mSeriesDef, seriesIndex) => {
                var mDataIndex = mNameToIndexMap[mSeriesDef.name];
                if (mDataIndex != null && mDataIndex != undefined) {
                    var mDataPoints = [];

                    var mDeleteCount = 0;
                    
                    // If the data is in the display range and the data is not overridden by this increment
                    elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex].options.data.forEach((point) => {
                        if (point[0] >= graphModel.displayStartTimestamp && point[0] < graphModel.incrementStartTimestamp) {
                            mDataPoints.push(point);
                        }
                        else {
                            mDeleteCount++;
                        }
                    });
                    
                    graphModel.entries.forEach((entry) => {
                        if (entry.values[mDataIndex] != null && elexity.admin.monitor.graphs.performanceLiveGraph.series != null  && elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex] != null) {
                            var mValue = entry.values[mDataIndex];
                            mDataPoints.push([entry.timestamp, mValue]);
                        }
                    });
                    
//                    console.log("Series size after add = " + mDataPoints.length);

                    mDataPoints.sort((a,b) => a[0] - b[0]);
 
                    if (elexity.admin.monitor.graphs.performanceLiveGraph.series != null  && elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex] != null) {
                        elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex].setData(mDataPoints, false);                        
                    }                   

//                    console.log("Series size after setData = " + elexity.admin.monitor.graphs.performanceLiveGraph.series[seriesIndex].options.data.length);                    
                }
            });

            elexity.admin.monitor.graphs.performanceLiveGraph.redraw();            
            
            var mGraphUpdateDefinition = {
                subtitle: { text: elexity.admin.monitor.graphs.liveHistorySubtitle }                
            };

            mGraphUpdateDefinition.xAxis = {
                min : graphModel.displayStartTimestamp,
                max : graphModel.displayEndTimestamp                
            };
            
            var mPlotBands = elexity.admin.monitor.graphs.calculatePlotBands(graphModel);
            var mPlotLines = elexity.admin.monitor.graphs.calculatePlotLines(graphModel);
            if (mPlotBands.length != 0) {
                mGraphUpdateDefinition.xAxis.plotBands = mPlotBands;
            }
            if (mPlotLines.length != 0) {
                mGraphUpdateDefinition.xAxis.plotLines = mPlotLines;
            }
            
            elexity.admin.monitor.graphs.performanceLiveGraph.update(mGraphUpdateDefinition, false);
            
            elexity.admin.monitor.graphs.performanceLiveGraph.redraw();  
            elexity.admin.monitor.graphs.performanceLiveGraph.hideLoading();            
            elexity.admin.monitor.graphs.liveLoading = false;

            //set selected zoom for subtitle
            var zoomSelected = elexity.admin.monitor.graphs.performanceLiveGraph.rangeSelector.selected;
            var buttonOptions = elexity.admin.monitor.graphs.performanceLiveGraph.rangeSelector.buttonOptions;
            
            if (zoomSelected !== undefined) {
                var formattedIntervalText = buttonOptions[zoomSelected].text

                formattedIntervalText = formattedIntervalText + ' intervals ' + '(' + graphModel.timeZoneCode + ')'
                elexity.admin.monitor.graphs.selectedLiveZoom = formattedIntervalText
            }
        }
        elexity.admin.monitor.graphs.logEvent("renderLiveGraphData : exit");        
    },
    
    roundToNumber : function(num, precision) {
        if (num == null || num == undefined) {
            return null;
        }
        else {
            var mMult = 10 ** precision;
            var mRet = Math.round(num * mMult) / mMult;
            return mRet;
        }
    },
    
    buildDateString : function(ts, tz) {
        var mFormat = new Intl.DateTimeFormat('en-GB', {
            dateStyle: "medium",            
            timeStyle: "long",
            hourCycle: "h23",
            timeZone: tz
        });
        
        var mDate = new Date(ts);

        return mFormat.format(mDate);
    },

    buildDateRangeString : function(tsFrom, tsTo, tz) {
        var mFormat = new Intl.DateTimeFormat('en-GB', {
            dateStyle: "medium",            
            // timeStyle: "long",
            // hourCycle: "h24",
            timeZone: tz
        });
        
        var mDateFrom = new Date(tsFrom);
        var mDateTo = new Date(tsTo);

        var mRet = '';

        try {
            mRet = mFormat.format(mDateFrom) + ' to ' + mFormat.format(mDateTo);            
        }
        catch (e) {
            
        }
        
        return mRet;
    }
}

export { elexity }